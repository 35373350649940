import React, { useEffect, useState } from 'react';
import { Modal, Transfer, message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase/app";

import { useFbObjectKeys, useCheckOnline } from '../../util';
import {updateAssignMember} from '../../redux/actions/assignUser';
import { getUsersAsync } from "../../redux/actions/user";
import './index.css';

export default function AssignUserModal({ visible, onCreate, onCancel, projectId }) {
    const { users } = useSelector((state) => state.userReducer);
    const [isOnline] = useCheckOnline();
    const [membersList] = useFbObjectKeys(firebase.database().ref(`projects/${projectId}/members`).orderByValue().equalTo(true));
    const [targetUserKeys, setTargetUserKeys] = useState([]);
    const [selectedUserKeys, setSelectedUserKeys] = useState([]);
    const [allUsersList, setAllUsersList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsersAsync());
    }, []);

    useEffect(() => {
        if (membersList && allUsersList) {
            const targetUsers = allUsersList.filter(x => membersList.includes(x.key));

            setTargetUserKeys(targetUsers.map(x => x.key));
        }
    }, [membersList, allUsersList]);

    useEffect(() => {
        if (Object.keys(users).length) {
            let allUsersList = [];

            Object.keys(users).forEach(key => {
                allUsersList.push({
                    key,
                    ...users[key]
                });
            });

            allUsersList.sort((a, b) => a.displayName.localeCompare(b.displayName));

            setAllUsersList(allUsersList.filter(x=> {
                const { inactive = false } = x;

                return !inactive;
            }));
        }
    }, [users]);

    const onOkClick = async () => {
        try {
            if (!isOnline) {
                throw Error('offline');
            }

            const updates = allUsersList.reduce((obj, cur) => {
                return {
                    ...obj,
                    [`/users/${cur.key}/projects/${projectId}`]: targetUserKeys.includes(cur.key)
                }
            }, {});

            dispatch(updateAssignMember(updates, projectId, targetUserKeys));
            setSelectedUserKeys([]);
        } catch(e) {
            message.error(e);
        }

        onCreate();
    }

    const onCancelClick = () => {
        resetTransfer()
        onCancel();
    }

    const resetTransfer = () => {
        setTargetUserKeys(membersList);
        setSelectedUserKeys([]);
    }

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetUserKeys(nextTargetKeys);

        if (direction === 'left') {
            setSelectedUserKeys(selectedUserKeys.filter(k => !moveKeys.includes(k)));
        }
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedUserKeys([...sourceSelectedKeys, ...targetSelectedKeys]);

        if (sourceSelectedKeys) {
            setTargetUserKeys([...sourceSelectedKeys, ...targetUserKeys]);
        }
    };

    const filterOption = (value, option) => {
        const { displayName, email } = option;

        return displayName.toLowerCase().indexOf(value) > -1 || email.toLowerCase().indexOf(value) > -1;
    };

    const setItem = (item) => {
        return (
            <>
                <span>{item.displayName}</span>
                <span className="App-user-modal__transfer-email">({item.email})</span>
            </>
        );
    };

    return (
        <Modal
            className="App-user-modal"
            title="Assign user"
            destroyOnClose
            centered
            visible={visible}
            onCancel={onCancelClick}
            onOk={onOkClick}
        >
            <Transfer
                oneWay={true}
                listStyle={{
                    height: 440,
                    width: 360
                }}
                showSearch
                filterOption={filterOption}
                showSelectAll={false}
                dataSource={allUsersList}
                targetKeys={targetUserKeys}
                selectedKeys={selectedUserKeys}
                render={(item) => setItem(item)}
                onChange={onChange}
                onSelectChange={onSelectChange}
            />
        </Modal>
    );
}
