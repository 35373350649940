import { combineReducers } from 'redux'

import userReducer from './user';
import projectReducer from './project';
import logReducer from './log';

const rootReducer = combineReducers({
    userReducer,
    projectReducer,
    logReducer
});

export default rootReducer;