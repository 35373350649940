import { FieldTimeOutlined } from "@ant-design/icons";

import "./index.css";
import { Login } from "../index";

function Header() {
    return (
        <div className="App-header-wrapper">
            <div className="App-header-logo">
                <FieldTimeOutlined /> 
            </div>
            <span className="App-header-title">Time Tracker</span>
            <div className="App-header-profile">
                <Login />
            </div>
        </div>
    )
}

export default Header