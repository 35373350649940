import React,  { useMemo } from 'react';
import { Result } from 'antd';

const initStatus = new Map([
    [
        "404",
        {
            status: "404",
            title: "404",
            subTitle: "Sorry, the page you visited does not exist."
        }
    ],
    [
        "403",
        {
            status: "403",
            title: "403",
            subTitle: "Sorry, you are not authorized to access this page."
        }
    ]
]);

function ErrorResult ({ status }) {
    const currentStatus = useMemo(() =>initStatus.get(status), [status]);

    return (
        <Result
            {...currentStatus}
        />
    )
}

export default ErrorResult;