import React from 'react';
import dayjs from 'dayjs';
import { database, firebaseServer } from '../firebase';
import { transformObjectToArray, useCheckOnline } from '../util';

const UNIX_DAY = 24 * 60 * 60;
const UNIX_WEEK = 7 * UNIX_DAY;
const UNIX_MONTH = 30 * UNIX_DAY;
const UNIX_MAP = {
	month: UNIX_MONTH,
	week: UNIX_WEEK,
	day: UNIX_DAY
};

const { createContext, useContext } = React;

// content
export const ProjectContext = createContext(null);

// interface
export const IProjectProvider = (props) => {
    const createProject = () => {
        throw Error('Your should impl method');
    }

    const editProject = () => {
        throw Error('Your should impl method');
    }

    const subscribeProjectsList = () => {
        throw Error('Your should impl method');
    }

    const unSubscribeProjectsList = () => {
        throw Error('Your should impl method');
    }

    const value = {
        createProject: props.createProject || createProject,
        subscribeProjectsList: props.subscribeProjectsList || subscribeProjectsList,
        unSubscribeProjectsList : props.unSubscribeProjectsList || unSubscribeProjectsList,
        editProject: props.editProject || editProject
    };

    return (
        <ProjectContext.Provider value={value}>
            {props.children}
        </ProjectContext.Provider>
    );
};

// impl
export const ProjectProviderImplementation = (props) => {
    const projectRef =  database.ref("/projects");
    const [isOnline] = useCheckOnline();

    const createProject = (preload) => {
        if (!isOnline) {
            throw new Error("offline");
        }

        const { data, activeUser } = preload;
        const { values } = data;
        const { startDate, periodType, periodValue } = values;
		const endDate = startDate * 1 + UNIX_MAP[periodType] * periodValue - UNIX_MAP["day"];
        const currentUNIX = dayjs(dayjs().format('YYYY-MM-DD')).unix();



        const projectEntity = Object.assign({}, values, {
            inactive: false,
            createdAt: firebaseServer.ServerValue.TIMESTAMP,
            updatedAt: firebaseServer.ServerValue.TIMESTAMP,
            userId: activeUser.userId,
            period: {
                [startDate]: { startDate, endDate, active: (currentUNIX >= startDate).toString()}
            },
            members: {
                [activeUser.userId]: true
            }
        });

        return projectRef.push(projectEntity);
    }

    const editProject = (preload) => {
        if (!isOnline) {
            throw new Error("offline");
        }
        const { data, currentProjectLists } = preload;
        const { key, values } = data;

        const projectEntity = Object.assign(
            {},
            currentProjectLists.find((x) => x.key === key),
            values,
            { updatedAt: firebaseServer.ServerValue.TIMESTAMP }
        );

        return projectRef.update({
            [`/${key}`]: projectEntity,
        });
    }

    const subscribeProjectsList = (callback, errorCallback) => {
        projectRef.on('value', (snapshot) => {
            const value = snapshot.val();

            if (callback) {
                callback(value ? transformObjectToArray(value) : []);
            }
        }, (error) => {
            if (errorCallback) {
                errorCallback(error);
            }
        });
    }

    const unSubscribeProjectsList = () => {
        projectRef.off();
    }

    const value = {
        createProject,
        editProject,
        subscribeProjectsList,
        unSubscribeProjectsList
    }

    return (
        <IProjectProvider {...value}>
            {props.children}
        </IProjectProvider>
    )
}

// service hooks
export const useProjectService = () => {
    return useContext(ProjectContext);
};
