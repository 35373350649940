export const userType = {
    ADD_USER: 'ADD_USER',
    GET_USERS: 'GET_USERS',
    GET_CURRENT_USER: 'GET_CURRENT_USER',
    UPDATE_ACTIVE_USER: 'UPDATE_ACTIVE_USER',
    AUTH_CHECKING: 'AUTH_CHECKING',
    INVALID_EMAIL: 'INVALID_EMAIL',
    INACTIVE_USER: 'INACTIVE_USER',
};

export const projectType = {
    GET_PROJECTS: 'GET_PROJECTS',
    GET_PROJECT: 'GET_PROJECT',
    GET_PERIODS: 'GET_PERIODS'
};

export const logType = {
    GET_LOGS: 'GET_LOGS',
    GET_USER_LOGS_BY_PERIOD: 'GET_USER_LOGS_BY_PERIOD',
    GET_NO_PERIOD_LOGS: 'GET_NO_PERIOD_LOGS',
    GET_NO_PERIOD_LOGS_BY_USER: 'GET_NO_PERIOD_LOGS_BY_USER',
    DELETE_LOGS: 'DELETE_LOGS'
};
