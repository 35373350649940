import React, { useMemo } from "react";
import {
	Card,
	Badge,
	Button,
	message,
	Tooltip,
	Popconfirm,
} from "antd";
import {
	EditOutlined,
	UserAddOutlined,
	DisconnectOutlined,
} from "@ant-design/icons";
import { useBoolean } from "ahooks";
import firebase from "firebase/app";
import "firebase/database";
import day from "dayjs";

import { AssignUserModal } from "../index";
import "./index.css";

export default function ProjectItemCard({
	role,
	project,
	onProjectEditBtnClick,
	onProjectContentClick,
	isAssign
}) {
	const {
		inactive,
		projectId,
		displayName,
		description,
		periodValue,
		periodType,
		startDate
	} = project;

	const [
		isAssignUserModalVisible,
		{ setTrue: openAssignUserModal, setFalse: closeAssignUserModal },
	] = useBoolean(false);

	const onAddUser = () => {
		openAssignUserModal();
	};

	const onEditOpen = () => {
		onProjectEditBtnClick(projectId);
	};

	const onToggleActive = async (e) => {
		try {
			await firebase.database().ref(`projects/${projectId}`).update({
				inactive: !inactive,
			});

			message.info("successfully");
		} catch (e) {
			message.error(e);
		}
	};

    const handleClick = (e) => {
        if (inactive) {
            e.stopPropagation();
            message.info('You can not log inactive project.');
        } if (!isAssign) {
            e.stopPropagation();
            message.info('You have not been assigned to this project, please connect your manager.');
        }  else {
            onProjectContentClick(projectId);
        }
    }

	const badgeStatus = useMemo(
		() => ({
			color: inactive ? "#d4380d" : "blue",
			text: inactive ? "Inactive" : "Active",
		}),
		[inactive]
	);

	const setTitle = () => {
		const displayTitle = `${displayName} Timesheet`;

		return (
			<Tooltip title={displayTitle} placement="topLeft">
				<div className="App-project-item-card_title" onClick={handleClick}>
					{displayTitle}
				</div>
			</Tooltip>
		);
	};

	const setActions = () => {
		if (!role.includes("admin")) {
			return;
		}

		return [
			<Tooltip title="Add User" onClick={onAddUser}>
				<UserAddOutlined />
			</Tooltip>,
			<Tooltip key="edit" title="Edit" onClick={onEditOpen}>
				<EditOutlined />
			</Tooltip>,
			<Popconfirm
				title="Are you sure to inactive/active this task?"
				onConfirm={onToggleActive}
			>
				<Tooltip key="delete" title="Inactive/Active">
					<DisconnectOutlined />
				</Tooltip>
			</Popconfirm>
		];
	};

	const setMetaTitle = () => {
		const displayPeriod = periodType == 'none' ? periodType : (
			`${periodValue} ${periodType}${Number(periodValue) > 1 ? 's' : ''}`
		);

		return (
			<>
				{ startDate && <p>Start date: { day(startDate * 1000).format('ll') }</p> }
				{ <p>Period: { displayPeriod }</p> }
			</>
		);
	};

	return (
		<Badge.Ribbon {...badgeStatus} className="App-project-item-card__ribbon">
			<Card
				className="App-project-item-card"
				title={setTitle()}
				hoverable={true}
				actions={setActions()}
				extra={<Button
					type="primary"
					className="App-project-item-card__log-button"
					onClick={handleClick}>
						Log time
				</Button>}
			>
				<Card.Meta
					style={{ wordBreak: "break-word", height: '100px', overflowY: 'auto' }}
					title={setMetaTitle()}
					description={description}
					onClick={handleClick}
				></Card.Meta>
			</Card>
			<AssignUserModal
				projectId={projectId}
				visible={isAssignUserModalVisible}
				onCancel={closeAssignUserModal}
				onCreate={closeAssignUserModal}
			/>
		</Badge.Ribbon>
	);
}
