import { projectType } from '../constant/actionTypes';

const initialState = {
	projects: null
}

const projectReducer = (state = initialState, action) => {
	switch (action.type) {
		case projectType.GET_PROJECTS:
			return Object.assign({}, state, { 'projects': action.projects });
		case projectType.GET_PROJECT:
			const { projectId, project } = action;

			return Object.assign({}, state, { [projectId]: project });
		default:
			return state;
	}
}

export default projectReducer;
