import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = (props) => {
	const {
		user: { role: userRole },
		role: routeRole,
		backUrl,
		...otherProps
	} = props;
	const hasRole = !routeRole || userRole === "admin" || userRole === routeRole;

	if (hasRole) {
		return <Route {...otherProps} />;
	} else {
		return <Redirect to={backUrl} />;
	}
};

export default AuthRoute;
