import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { googleSignOut } from "../../redux/actions/auth";
import './index.css';

const setAvatar = name => {
	if (!name) {
		return '';
	}

	const firstName = name.split(' ')[0];
	const avatarName = firstName.length <= 1 ? firstName : firstName[0];

	return avatarName;
};

function Login() {
	const { activeUser } = useSelector(state => state.userReducer);

	const signOut = () => {
		try {
			googleSignOut();
		} catch (e) {
			console.log(e);
		}
	};

	const menu = (
		<Menu className="App-login__menu">
			<Menu.Item key="user-info">
				<div className="App-login__user-wrapper">
					<Avatar className="App-login__user-avatar" size="large">{setAvatar(activeUser.displayName)}</Avatar>
					<div className="App-login__user-info">
						<span className="App-login__user-info-name">{activeUser.displayName}</span>
						<span className="App-login__user-info-email">{activeUser.email}</span>
					</div>
				</div>
			</Menu.Item>
			<Menu.Item key="logout">
				<div
					className="App-login__logout"
					onClick={signOut}
				>
					<LogoutOutlined className="App-login__logout-icon"/>
					<span className="App-login__logout-label">Sign Out</span>
				</div>
			</Menu.Item>
		</Menu>
	);

	return (
		<div className="App-login__container">
			{ activeUser && activeUser.displayName &&
				(
					<Dropdown overlay={menu} trigger="click">
						<Avatar className="App-login__avatar">{setAvatar(activeUser.displayName)}</Avatar>
					</Dropdown>
				)
			}
		</div>
	);
}

export default Login;
