import { database } from '../../firebase';
import { userType } from '../constant/actionTypes';

const userRef = database.ref('users/');
const adminRef = database.ref('admins/');

export function updateActiveUser(activeUser, authChecking) {
    return async dispatch => {
        if (Object.keys(activeUser).length === 0) {
            dispatch({
                type: userType.UPDATE_ACTIVE_USER,
                activeUser,
                authChecking
            });
        } else {
            await updateUserSignAt({
                userId: activeUser.userId,
            });

            dispatch({
                type: userType.UPDATE_ACTIVE_USER,
                activeUser,
                authChecking
            })
        }
    }
};

export function getActiveUserSync(currentUser) {
    return dispatch => {
        userRef.get().then(snapshot => {

            const users = snapshot.val() || {};
            const { uid } = currentUser;
            const user = users[uid];

            adminRef.get().then(snapshot => {
                const { email, displayName } = currentUser;
                const key = email.split('.').join('-');
                const admins = snapshot.val() || [];
                const isAdmin = admins.hasOwnProperty(key);

                if (!user) {
                    const role = isAdmin ? "admin" : "user";
                    const userInfo = {
                        email,
                        displayName,
                        role,
                    };

                    dispatch(addUser(uid, userInfo));
                } else {
                    const activeUser = { ...user, userId: uid };

                    dispatch(updateActiveUser(activeUser, false));
                }
            });
        });
    };
};

export function getUsers(users) {
    return {
        type: userType.GET_USERS,
        users
    };
};

export function getUsersAsync() {
    return dispatch => {
        userRef.get().then(snapshot => {
            const users = snapshot.val();

            if (users) {
                dispatch(getUsers(users))
            } else {
                console.log('There is no user')
            }
        });
    };
};

export function addUser(uid, userInfo) {
    return dispatch => {
        database.ref(`users/${uid}`).set(userInfo, error => {
            if (error) {
                console.log(`Add user failed: ${error.message}`)
            } else {
                const activeUser = { ...userInfo, userId: uid };

                dispatch(updateActiveUser(activeUser, false));
            }
        });
    };
};

export async function updateUserSignAt({
    userId,
}) {
    const p = await database.ref(`users/${userId}/signAt`).set(Date.now());
}

export async function updateUserRole(userId, role, email) {
    const p = database.ref(`users/${userId}/role`).set(role);
    const key = email.split('.').join('-');

    if (role === 'admin') {
        return Promise.all([p, addAdmin(key, email)]);
    } else {
        return Promise.all([p, deleteAdmin(key)]);
    }
};

export async function updateUserActive(userId, inactive) {
    const p = await database.ref(`users/${userId}/inactive`).set(inactive);
    console.log(p)
}

function deleteAdmin(key) {
    return database.ref(`admins/${key}`).remove();
};

function addAdmin(key, email) {
    return database.ref(`admins/${key}`).set(email);
};