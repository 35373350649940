import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Spin, notification } from "antd";
import { InfoCircleTwoTone } from '@ant-design/icons';

import "./App.css";
import { Home } from "./pages";
import { SideBar, AuthRoute, Header } from "./components";
import { adminRoutes, userRoutes, publicRoutes } from "./routes/index";
import { getAuthState } from "./redux/actions/auth";

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAuthState());
	}, []);

	const { authChecking, activeUser, invalidEmail, isUserInactive } = useSelector(state => state.userReducer);


	useEffect(() => {
		if (isUserInactive) {
			notification.open({
				message: (<p style={{ fontSize: '18px', fontWeight: "bold" }}>
					Invalid Account
				</p>),
				description: <span style={{ fontSize: '14px' }}>Your hatchbetter account is inactive. Please contact your administrator</span>,
				icon: <InfoCircleTwoTone />,
				style: {
					background: 'transparent',
					borderRadius: '8px'
				}
			});
		}
	}, [isUserInactive])

	useEffect(() => {
		const icon = <InfoCircleTwoTone />;
		const message = (
			<p style={{ fontSize: '18px', fontWeight: "bold" }}>
				Invalid Account
			</p>
		);
		const description = (
			<span style={{ fontSize: '14px' }}>Please get started with your hatchbetter account.</span>
		);
		const style = {
			background: 'transparent',
			borderRadius: '8px'
		};

		if (invalidEmail) {
			notification.open({
				message,
				description,
				icon,
				style,
			});
		}
	}, [invalidEmail]);

	useEffect(() => {

	})

	const getAppContent = () => {
		if (activeUser.displayName) {
			return (
				<div className="App">
					<Header ></Header>
					<div className="App-body">
						<SideBar user={activeUser} />
						<div className="App-body__wrapper">
							<div className="App-body__wrapper-content">
								<Switch>
									{
										adminRoutes.map(route => (
											<AuthRoute
												user={activeUser}
												key={route.path}
												{...route}
											/>
										))
									}
									{
										userRoutes.map(route => (
											<AuthRoute
												user={activeUser}
												key={route.path}
												{...route}
											/>
										))
									}
									{
										publicRoutes.map(route => (
											<AuthRoute
												user={activeUser}
												key={route.path}
												{...route}
											/>
										))
									}
								</Switch>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<>
					<Route path="/" component={Home} />
					<Redirect from="*" to="/" />
				</>
			);
		}
	};

	return (
		<>
			{!authChecking ? (
				<Router>
					{getAppContent()}
				</Router>
			) : (
				<Spin
					className="App-auth-checking"
					size="large"
					tip="Connecting..."
				/>
			)}
		</>
	);
}

export default App;
