import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Table, Modal, Select, Input, Avatar, Switch } from "antd";
import {
	SettingTwoTone,
	SearchOutlined,
	UserAddOutlined,
	UserOutlined,
	ExclamationCircleFilled
} from '@ant-design/icons';

import "./index.css";
import { getUsersAsync, updateUserRole, getUsers, updateUserActive } from '../../redux/actions/user';

const { Option } = Select;

const pagination = {
	hideOnSinglePage: true,
	pageSize: 15,
	showSizeChanger: false
};

const sortUser = (a, b) => {
	return a.displayName.localeCompare(b.displayName);
};

const Colors = ['#f56a00', '#7265e6', '#ee7026f2', '#ffbf00', '#eb2f96', '#00a2ae', '#096dd9'];

const setAvatar = (name, index) => {
	if (!name) {
		return {};
	}

	const firstName = name.split(' ')[0];
	const avatarName = firstName.length <= 1 ? firstName : firstName[0];
	const color = Colors[index % (Colors.length)];

	return {
		avatarName,
		color
	};
};

const Users = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUsersAsync());
	}, []);

	const [modalData, setModalData] = useState({});
	const [userList, setUserList] = useState([]);
	const [dataSource, setDataSource] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { users } = useSelector(state => state.userReducer);

	const handleInActiveClick = (record) => {
		Modal.confirm({
			title: `Are you sure to ${record?.inactive ? 'active' : 'inactive'} the user?`,
			icon: <ExclamationCircleFilled />,
			onOk: async () => {
				const { userId, inactive = false } = record;
				try {
					updateUserActive(userId, !inactive )
					.then(() => {
						dispatch(getUsers(Object.assign({}, users, {[userId]: {
							...record,
							inactive: !inactive
						}})));
					});
				} catch (e) {
					Modal.destroyAll();
					console.log(e);
				}
			},
			centered: true,
		});
	}

	const columns = [
		{
			title: 'User',
			dataIndex: 'displayName',
			width: 180,
			render: (text, record, index) => {
				const { displayName, email } = record;
				const { avatarName, color } = setAvatar(displayName, index);

				return (
					<div className="App-users__user-wrapper">
						<Avatar style={{ backgroundColor: color }} size="large">{avatarName}</Avatar>
						<div className="App-users__userInfo">
							<span className="App-users__userInfo-name">{displayName}</span>
							<span className="App-users__userInfo-email">{email}</span>
						</div>
					</div>
				);
			}
		},
		{
			title: 'Role',
			dataIndex: 'role',
			width: 130,
			render: (text, record) => {
				return (
					<div className="App-users__role" onClick={(e) => {showModal(e, record)}} >
						<SettingTwoTone />
						<span className="App-users__role-setting">{text.slice(0, 1).toLocaleUpperCase()}{text.slice(1)}</span>
					</div>
				);
			}
		},
		{
			title: 'Last Sign In',
			width: 80,
			dataIndex: 'signAt',
			render: (_, record) => {
				console.log(record, 'record')
				return record?.signAt ? (new Date(record.signAt)).toLocaleString() : 'No Record'
			}
		},
		{
			title: 'Inactive',
			width: 40,
			dataIndex: 'inactive',
			// key: 'inactive',
			render: (_, record) => {
				return (
					<div className="App-users__active">
						<Switch checked={record?.inactive ?? false} onClick={() => handleInActiveClick(record)}></Switch>
					</div>
				)
			}
		}
	];

	useMemo(() => {
		const data = [];

		for (let userId in users) {
			const user = Object.assign({}, users[userId], {userId});

			data.push(user);
		}

		setDataSource(data.sort(sortUser));
		setUserList(data.sort(sortUser));
	}, [users]);

	const showModal = (e, data) => {
		setModalData(data);
	  	setIsModalVisible(true);
	};

	const handleOk = () => {
		const { userId, role, email } = modalData;
		const user = users[userId];

		if (role === user.role) {
			return setIsModalVisible(false);
		}

		user.role = role;

		updateUserRole(userId, role, email)
			.then(() => {
				dispatch(getUsers(Object.assign({}, users, {[userId]: user})));
				setIsModalVisible(false);
			});
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const getModalTitle = () => {
		const { displayName, email } = modalData;
		const { avatarName } = setAvatar(displayName, 0);

		return (
			<div className="App-users__user-wrapper">
				<Avatar size="large" style={{ backgroundColor: '#f56a00' }}>{avatarName}</Avatar>
				<div className="App-users__userInfo">
					<span className="App-users__userInfo-name">{displayName}</span>
					<span className="App-users__userInfo-email">{email}</span>
				</div>
			</div>
		);
	};

	const handleSelectChange = (role) => {
		setModalData(Object.assign({}, modalData, {role}))
	};

	const getModalContent = () => {
		const { role } = modalData;

		return (
			<Select value={role} style={{ width: 200, fontSize: '17px' }} onChange={handleSelectChange}>
				<Option value="admin"><UserAddOutlined style={{ color: '#1890ff'}}/> Admin</Option>
				<Option value="user"><UserOutlined style={{ color: '#1890ff'}}/> user</Option>
			</Select>
		);
	};

	const setRowKey = (user)  => {
		return user.userId;
	};

	const onInputChange = (e) => {
		const text = e.target.value.toLocaleLowerCase();

		if (text) {
			setUserList(dataSource.filter(x => x.displayName.toLocaleLowerCase().includes(text)));
		} else {
			setUserList(dataSource);
		}
	};

	return false ? (
		<Spin className="loading" />
	) : (
		<>
			<Input
				size="large"
				className="App-users-filter-input"
				placeholder="Search User"
				prefix={<SearchOutlined />}
				onChange={onInputChange}
				allowClear={onInputChange}
			/>
			<div className="App-users-table">
				<Table
					size="middle"
					columns={columns}
					dataSource={userList}
					pagination={pagination}
					rowKey={setRowKey}
				/>
				<Modal
					className="App-users__modal"
					visible={isModalVisible}
					title={getModalTitle()}
					okText="Update role"
					onOk={handleOk}
					onCancel={handleCancel}
					width="580px"
				>
					{getModalContent()}
				</Modal>
			</div>
		</>
	);
};

export default Users;
