import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Input, Spin, Table } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import day from 'dayjs';

import "./index.css";
import { getProjectsAsync } from '../../redux/actions/project';
import { getUsersAsync } from "../../redux/actions/user";

const columns = [
	{
		title: 'Project',
		dataIndex: 'projectName',
		width: 250,
		render: (text, record) => {
			const url = `/reports/${record.projectId}`;

			return <Link to={url}>{text}</Link>
		}
	},
	{
		title: 'Period',
		dataIndex: 'period',
		width: 150,
		filters: [
			{ text: 'Week', value: 'week' },
			{ text: 'Month', value: 'month' },
			{ text: 'None', value: 'none' }
		],
		render: (v, record) => (
			record.periodType !== 'none' ? `${record.periodValue} ${record.periodType}${record.periodValue > 1 ? 's' : ''}` : '-'
		),
		onFilter: (v, record) => record.periodType === v
	},
	{
		title: 'Status',
		dataIndex: 'status',
		width: 150,
		render: (text, record) => {
			let status = 'Active';
			let className = 'App-reports_status-active';

			if (record.inactive) {
				status = 'Inactive'
				className = 'App-reports_status-inactive';
			}

			return <span className={className}>{status}</span>;
		}
	},
	{
		title: 'Created By',
		dataIndex: 'createdBy',
		width: 200,
		render: (v, record) => record.user && record.user.displayName || ''
	},
	{
		title: 'Created At',
		dataIndex: 'createdAt',
		width: 200,
		render: v => day(v).format("ll")
	},
	{
		title: 'Start At',
		dataIndex: 'startDate',
		width: 150,
		render: v => v ? day(v * 1000).format("ll") : '-'
	}
];

const pagination = {
	hideOnSinglePage: true,
	pageSize: 16,
	showSizeChanger: false
};

const sortData = (first, second) => {
	return first.projectName.localeCompare(second.projectName);
};

const Details = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [projectData, setProjectData] = useState([]);
	const [projectList, setProjectList] = useState([]);

	useEffect(() => {
		dispatch(getProjectsAsync());
		dispatch(getUsersAsync());
	}, []);

	const { projects } = useSelector(state => state.projectReducer);
	const { users } = useSelector(state => state.userReducer);

	useMemo(() => {
		if (!projects || !Object.keys(projects).length || !users || !Object.keys(users).length) {
			setLoading(false);
			return [];
		}

		const activeProjects = [];
		const inactiveProjects = [];

		for (let key in projects) {
			const projectData = projects[key];
			const {
				displayName,
				periodValue,
				periodType,
				inactive,
				startDate,
				createdAt,
				userId
			} = projectData;

			const project = {
				key,
				projectId: key,
				projectName: displayName,
				periodValue,
				periodType,
				inactive,
				startDate,
				createdAt,
				user: users[userId]
			};

			if (inactive) {
				inactiveProjects.push(project);
				inactiveProjects.sort(sortData);
			} else {
				activeProjects.push(project);
				activeProjects.sort(sortData);
			}
		}

		const allProjects = activeProjects.concat(inactiveProjects);

		if (allProjects.length) {
			setLoading(false);
			setProjectData(allProjects);
			setProjectList(allProjects);
		}
	}, [projects, users]);

	const onInputChange = (e) => {
		const text = e.target.value.toLocaleLowerCase();

		if (text) {
			setProjectList(projectData.filter(x => x.projectName.toLocaleLowerCase().includes(text)));
		} else {
			setProjectList(projectData);
		}
	};

	return loading ? (
		<Spin className="loading" />
	) : (
		<>
			<Input
				size="large"
				className="App-projects-filter-input"
				placeholder="Search Project"
				prefix={<SearchOutlined />}
				onChange={onInputChange}
				allowClear={onInputChange}
			/>
			<div className="App-projects-table">
				<Table
					className="App-reports__table"
					columns={columns}
					dataSource={projectList}
					pagination={pagination}
				/>
			</div>
		</>
	);
};

export default Details;
