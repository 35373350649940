import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import {
	ProjectOutlined,
	AreaChartOutlined,
	UserAddOutlined
} from "@ant-design/icons";

const MenuStyle = {
	width: 280,
	height: "max-content",
	padding: "20px 10px",
	borderRadius: 12,
	boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.08), 0px 2px 12px rgba(0, 0, 0, 0.08)",
	fontSize: 18,
	backgroundColor: "rgba(255, 255, 255, 0.6)"
};
const menuArr = [
	{
		path: "/projects",
		label: "Projects",
		key: "/projects",
		icon: ProjectOutlined,
		role: "user"
	},
	{
		path: "/reports",
		label: "Reports",
		key: "/reports",
		icon: AreaChartOutlined,
		role: "admin"
	},
	{
		path: "/users",
		label: "Users",
		key: "/users",
		icon: UserAddOutlined,
		role: "admin"
	}
];

function SideBar(props) {
	const { user: { role: userRole } } = props;
	const currentPath = useLocation().pathname || menuArr[0].path;
	const selectedKeys = [currentPath];

	return (
		<Menu
			mode="inline"
			style={MenuStyle}
			selectedKeys={selectedKeys}
		>
			{menuArr.map(({ path, label, key, icon, role }) => {
				const ActiveIcon = icon;

				if (userRole === "admin" || userRole === role) {
					return (
						<Menu.Item key={key} icon={<ActiveIcon style={{ fontSize: '18px' }}/>}>
							<Link to={path}>{label}</Link>
						</Menu.Item>
					);
				}

				return "";
			})}
		</Menu>
	);
}

export default SideBar;
