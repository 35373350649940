import { database } from '../../firebase';

export function updateAssignMember(updates, projectId, userKeys) {
    const dbRef = database.ref();

    return async () => {
        await dbRef.update(updates);
        await dbRef.child(`projects/${projectId}/members`).set(
            userKeys.reduce((obj, x) => ({
                ...obj,
                [x]: true
            }), {})
        );
    }
};
