import { Reports, ReportDetails, Users } from "../pages";

const adminRoutes = [
	{
		path: "/reports",
		component: Reports,
		exact: true,
		role: "admin",
		backUrl: "/noauth",
	},
	{
		path: "/reports/:projectId",
		component: ReportDetails,
		exact: true,
		role: "admin",
		backUrl: "/noauth",
	},
	{
		path: "/users",
		component: Users,
		exact: true,
		role: "admin",
		backUrl: "/noauth",
	}
];

export default adminRoutes;
