import { logType } from '../constant/actionTypes';

const initialState = {
	logData: null
}

const logReducer = (state = initialState, action) => {
	switch (action.type) {
		case logType.GET_LOGS:
			return Object.assign({}, state, { logData: action.logData });
		case logType.GET_NO_PERIOD_LOGS:
			return Object.assign({}, state, { logData: action.logData });
		case logType.GET_USER_LOGS_BY_PERIOD:
			return Object.assign({}, state, { periodLogs: action.logData });
		case logType.GET_NO_PERIOD_LOGS_BY_USER:
			return Object.assign({}, state, { noPeriodLogs: action.logData });
		case logType.DELETE_LOGS:
			return Object.assign({}, state, { logData: action.logData });
		default:
			return state;
	}
}

export default logReducer;
