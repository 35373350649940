import React from "react";
import { useDispatch } from "react-redux";

import "./index.css";
import { Header } from "../../components";
import { googleSignInPopup, checkAuthState } from "../../redux/actions/auth";

function Home() {
    const dispatch = useDispatch();

    function signIn() {
		try {
            dispatch(checkAuthState(true));
            googleSignInPopup();
		} catch (e) {
			console.log(e);
		}
	}

    return (
        <div className="App-home">
            <Header />
            <div className="App-home-content">
                <h className="App-home-content__title">
                    Time Tracker
                </h>
                <p className="App-home-content__subtitle">
                    A better way to track time and work.
                </p>
                <div 
                    className="App-home-content__start"
                    onClick={signIn}
                >
                    Get Started
                </div>
            </div>
        </div>
    );
}

export default Home;
