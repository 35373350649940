import React, { useMemo } from "react";
import { Select } from "antd";
import { LockOutlined, ClockCircleTwoTone, StopOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Option } = Select;

export default function PeriodSelect({ periodList, onSelectedPeriodChanged, mode, defaultValue }) {
	const periodsListArray = useMemo(() => {
		return periodList.sort((x, y) => y.startDate - x.startDate);
	}, [periodList]);

	const filterListArray = useMemo(() => {
		return periodList.filter(list => list.active !== "false")
			.sort((x, y) => y.startDate - x.startDate)
	},[periodList]);

	const currentPeriodKey = useMemo(() => {
		if (defaultValue) {
			return defaultValue.startDate;
		}
		
		if (filterListArray.length) {
			return filterListArray[0].startDate;
		}

		return 'No Active Period';
	}, [filterListArray]);

	const handleChange = (value) => {
		let selectedPeriod;

		if (mode === 'multiple') {
			selectedPeriod = periodList.filter(v => value.includes(v.startDate));
		} else {
			selectedPeriod = periodList.find(v => v.startDate === value);
		}

		if (onSelectedPeriodChanged) {
			onSelectedPeriodChanged(selectedPeriod);
		}
	};

	const getOptions = () => {
		if (!periodsListArray) {
			return;
		}

		return periodsListArray.map(x => {
			let icon = <ClockCircleTwoTone />;
			let iconStyle = { marginRight: '5px' };
			const isDisabled = x.active === "false";

			if (x.isLocked) {
				icon = <LockOutlined />;
				iconStyle = Object.assign({}, iconStyle, { color: "#eb2f96" });
			} else if (isDisabled) {
				icon = <StopOutlined />;
			}

			return <Option
				key={x.startDate}
				value={x.startDate}
				disabled={isDisabled}
			>
				<span style={iconStyle}>
					{icon}
				</span>
				{dayjs.unix(x.startDate).format("YYYY/MM/DD")}～
				{dayjs.unix(x.endDate).format("YYYY/MM/DD")}
			</Option>
		});
	}

	return (
		<Select
			mode={mode}
			showArrow
			onChange={handleChange}
			defaultValue={currentPeriodKey}
			style={{ width: 300 }}
		>
			{getOptions()}
		</Select>
	);
}
