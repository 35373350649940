import { Redirect } from "react-router-dom";
import { ErrorResult } from '../components';

const publicRoutes = [
	{
		path: "/noauth",
		component: () => <ErrorResult status="403" />,
		exact: true
	},
	{
		path: "/",
		component: () => <Redirect from="/" to="/projects" />,
		exact: true
	}
];

export default publicRoutes;
