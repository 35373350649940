import { database } from '../../firebase';
import { logType } from '../constant/actionTypes';

export function deleteLogData() {
    return {
        type: logType.DELETE_LOGS,
        logData: null
    };
};

function getLogData(logData) {
    return {
        type: logType.GET_LOGS,
        logData
    };
};

export function getLogDataAsync(projectId) {
    return dispatch => {
        database.ref(`log-sheet/${projectId}`).get().then(snapshot => {
            const logData = snapshot.val();

            if (logData) {
                dispatch(getLogData(logData));
            } else {
                dispatch(getLogData({}));
            }
        });
    }
};

function getUserLogDataByPeriod(logData) {
    return {
        type: logType.GET_USER_LOGS_BY_PERIOD,
        logData
    };
};

export function getUserLogDataByPeriodAsync(projectId, userId, period) {
    return dispatch => {
        database.ref(`log-sheet/${projectId}/${userId}/${period}`).on('value', snapshot => {
            const logData = snapshot.val();

            if (logData) {
                dispatch(getUserLogDataByPeriod(logData));
            } else {
                dispatch(getUserLogDataByPeriod({}));
            }
        });
    }
};

export function addLogAsync(projectId, period, logEntity) {
    const { userId, logId } = logEntity;

    return new Promise((res, rej) => {
        database.ref(`log-sheet/${projectId}/${userId}/${period}/${logId}`).set(logEntity, error => {
            if (error) {
                return rej(0);
            } else {
                return res(1);
            }
        });
    });
};

export function updateLogAsync(projectId, period, logEntity) {
    const { userId, logId } = logEntity;

    return new Promise((res, rej) => {
        database.ref(`log-sheet/${projectId}/${userId}/${period}/${logId}`).set(logEntity, error => {
            if (error) {
                return rej(0);
            } else {
                return res(1);
            }
        });
    });
};

export function deleteLogAsync(projectId, period, logData) {
    const { userId, logId } = logData;

    return new Promise((res, rej) => {
        database.ref().update({
            [`log-sheet/${projectId}/${userId}/${period}/${logId}/isDeleted`]: true
        }, error => {
            if (error) {
                return rej(0);
            } else {
                return res(1);
            }
        });
    });
};

/** No period log */
function getNoPeriodLogData(logData) {
    return {
        type: logType.GET_NO_PERIOD_LOGS,
        logData
    };
};

export function getNoPeriodLogDataAsync(projectId) {
    return dispatch => {
        database.ref(`no-period-log-sheet/${projectId}`).get().then(snapshot => {
            const logData = snapshot.val();

            if (logData) {
                dispatch(getNoPeriodLogData(logData));
            } else {
                dispatch(getNoPeriodLogData({}));
            }
        });
    }
};

function getNoPeriodLogByUser(logData) {
    return {
        type: logType.GET_NO_PERIOD_LOGS_BY_USER,
        logData
    };
};

export function getNoPeriodLogByUserAsync(projectId, userId) {
    return dispatch => {
        database.ref(`no-period-log-sheet/${projectId}/${userId}`).on('value', snapshot => {
            const logData = snapshot.val();

            if (logData) {
                dispatch(getNoPeriodLogByUser(logData));
            } else {
                dispatch(getNoPeriodLogByUser({}));
            }
        });
    }
};

export function addNoPeriodLogAsync(projectId, logEntity) {
    const { logDate, userId, logId } = logEntity;

    return new Promise((res, rej) => {
        database.ref(`no-period-log-sheet/${projectId}/${userId}/${logDate}/${logId}`).set(logEntity, error => {
            if (error) {
                return rej(0);
            } else {
                return res(1);
            }
        });
    });
};

export function updateNoPeriodLogAsync(projectId, logEntity) {
    const { userId, logId, logDate } = logEntity;

    return new Promise((res, rej) => {
        database.ref(`no-period-log-sheet/${projectId}/${userId}/${logDate}/${logId}`).set(logEntity, error => {
            if (error) {
                return rej(0);
            } else {
                return res(1);
            }
        });
    });
};

export function deleteNoPeriodLogAsync(projectId, logData) {
    const { userId, logDate, logId } = logData;

    return new Promise((res, rej) => {
        database.ref().update({
            [`no-period-log-sheet/${projectId}/${userId}/${logDate}/${logId}/isDeleted`]: true
        }, error => {
            if (error) {
                return rej(0);
            } else {
                return res(1);
            }
        });
    });
};