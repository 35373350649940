import React, {useEffect} from 'react';
import { Form, Button, DatePicker } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';

import './index.css';

const { RangePicker } = DatePicker;

export default function SearchLogForm({ datePeriod, onSearch, onRangeChange }) {
    const [form] = Form.useForm();

    useEffect(() => {
        const {startDate, endDate} = datePeriod;

        form.setFieldsValue({
            datePeriods: [dayjs(startDate * 1000), dayjs(endDate * 1000)]
        });
    }, [datePeriod]);

    const handleClick = () => {
        form.validateFields().then(fieldsValues => {
            const startDate = dayjs(fieldsValues.datePeriods[0].format("YYYY/MM/DD")).unix();
            const endDate = dayjs(fieldsValues.datePeriods[1].format("YYYY/MM/DD")).unix();

            onSearch({
                startDate,
                endDate
            });
        }).catch(error => {
            console.log(error);
        });
    };

    const onChange = dates => {
        const startDate = dayjs(dates[0].format("YYYY/MM/DD")).unix();
        const endDate = dayjs(dates[1].format("YYYY/MM/DD")).unix();

        onRangeChange({
            startDate,
            endDate
        });
    };

    return (
        <div className="App-date-period-picker">
            <Form
                form={form}
                name="log-search"
                layout="inline"
            >
                <Form.Item name="datePeriods" rules={[{ required: true, message: 'Date period is required!' }]}>
                    <RangePicker allowClear={false} format="YYYY/MM/DD" style={{ width: '350px' }} onChange={onChange}/>
                </Form.Item>
            </Form>
            <Button
                type="primary"
                shape="circle"
                icon={<SearchOutlined />}
                onClick={handleClick}
            />
        </div>
    );
}
