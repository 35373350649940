import { database, firebaseServer } from '../../firebase';
import { projectType } from '../constant/actionTypes';

const projectsRef = database.ref('projects/');

export function createProjectAsync(project) {
    const projectEntity = Object.assign(project, {
        createdAt: firebaseServer.ServerValue.TIMESTAMP,
        updatedAt: firebaseServer.ServerValue.TIMESTAMP
    });
    
    return new Promise((res, rej) => {
        projectsRef.push(projectEntity, error => {
            if (error) {
                return rej(0);
            } else {
                return res(1);
            }
        });
    });
};

function getProjects(projects) {
    return {
        type: projectType.GET_PROJECTS,
        projects
    };
};

export function getProjectsAsync() {
    return dispatch => {
        projectsRef.on('value', snapshot => {
            const projects = snapshot.val();

            if (projects) {
                dispatch(getProjects(projects));
            } else {
                console.log('No projects found');
            }
        });
    }
};

function getProject(projectId, project) {
    return {
        type: projectType.GET_PROJECT,
        projectId,
        project
    };
};

export function getProjectAsync(projectId) {
    return dispatch => {
        database.ref(`projects/${projectId}`).get().then(snapshot => {
            const project = snapshot.val();

            if (project) {
                dispatch(getProject(projectId, project));
            } else {
                console.log('No project found');
            }
        });
    }
};

export function updateProjectAsync(projectId, data) {
    const projectEntity = Object.assign({}, data, {
        updatedAt: firebaseServer.ServerValue.TIMESTAMP
    });
    
    return new Promise((res, rej) => {
        return database.ref(`projects`).update({
            [`/${projectId}`]: projectEntity
        }, error => {
            if (error) {
                return rej(0);
            } else {
                return res(1);
            }
        });
    });
};


function getPeriods(periods) {
    return {
        type: projectType.GET_PERIODS,
        periods
    };
};

export function getPeriodsAsync(projectId) {
    return dispatch => {
        database.ref(`projects/${projectId}/period`).get().then(snapshot => {
            const periods = snapshot.val();

            if (periods) {
                dispatch(getPeriods(periods));
            } else {
                console.log('No project found');
            }
        });
    }
};

export function updatePeriodAsync(projectId, periodKey, isLocked) {
    return new Promise((res, rej) => {
        database.ref().update({
            [`projects/${projectId}/period/${periodKey}/isLocked`]: isLocked
        }, error => {
            if (error) {
                return rej(0);
            } else {
                return res(1);
            }
        });
    });
};