import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { database } from '../firebase';

const useFireBaseObject = (ref) => {
    const [list, setList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const curRef = useRef(ref);

    const onValueChange = useCallback((snapshots) => {
        let tempList = [];
        snapshots.forEach(snapshot => {
            tempList.push(snapshot.val());
        });

        setLoading(false);
        setList(tempList);
    }, []);

    useEffect(() => {
        if (isEqual(ref, curRef.current)) {
            curRef.current = ref;
        }
    });

    useEffect(() => {
        curRef.current.on('value', onValueChange);
        return () => curRef.current.off('value', onValueChange);
    }, [onValueChange]);

    return useMemo(() => [list, isLoading], [list, isLoading]);
};

const useFbObjectVal = (ref) => {
    const [list, loading, error] = useObjectVal(ref);
    const value = useMemo(
        () =>
        (list
            ? transformObjectToArray(list)
            : undefined),
        [list]
    );

    return useMemo(() => [value, loading, error], [value, loading, error]);
};

const useFbObjectKeys = (ref) => {
    const [list, loading, error] = useObjectVal(ref);
    const value = useMemo(
        () =>
        (list
            ? Object.entries(list).map(x => x[0])
            : undefined),
        [list]
    );

    return useMemo(() => [value, loading, error], [value, loading, error]);
};

const isEqual = (v1, v2) => {
    const bothNull = !v1 && !v2;
    const equal =  !!v1 && !!v2 && v1.isEqual(v2);

    return bothNull || equal;
};

const transformObjectToArray = (obj) => {
    return Object.entries(obj).map(x => {
        return {
            key: x[0],
            ...x[1]
        }
    })
};

const useCheckOnline = () => {
    const ref = database.ref('.info/connected');
    const connectedRef = useRef(ref);
    const [isOnline, setIsOnline] = useState(false);

    const onValueChange = useCallback((snap) => {
        setIsOnline(snap.val());
    }, []);

    useEffect(() => {
        if (isEqual(ref, connectedRef.current)) {
            connectedRef.current = ref;
        }
    });

    useEffect(() => {
        connectedRef.current.on('value', onValueChange);

        return () => connectedRef.current.off('value', onValueChange);
    }, [onValueChange]);

    return [isOnline];
};

export {
    useFbObjectVal,
    useFireBaseObject,
    useFbObjectKeys,
    useCheckOnline,
    transformObjectToArray
};