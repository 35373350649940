import { userType } from '../constant/actionTypes';

const initialState = {
	users: {},
	activeUser: {},
	authChecking: true,
	invalidEmail: false,
	isUserInactive: false,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case userType.GET_USERS:
			return Object.assign({ ...state, 'users': action.users });
		case userType.UPDATE_ACTIVE_USER:
			return ({ ...state, 'activeUser': action.activeUser, 'authChecking': action.authChecking });
		case userType.AUTH_CHECKING:
			return ({ ...state, 'authChecking': action.authChecking });
		case userType.INVALID_EMAIL:
			return ({ ...state, 'authChecking': action.authChecking, 'invalidEmail': action.invalidEmail });
		case userType.INACTIVE_USER:
			return ({ ...state, authChecking: action.authChecking, isUserInactive: action.isUserInactive });
		default:
			return state;
	}
}

export default userReducer;
