import { Projects, Log } from "../pages";

const userRoutes = [
	{
		path: "/projects",
		component: Projects,
		exact: true,
		role: "user",
		backUrl: "/noauth",
	},
	{
		path: "/log/:projectId",
		component: Log,
		exact: true,
		role: "user",
		backUrl: "/noauth",
	},
];

export default userRoutes;
