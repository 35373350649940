export const PERIOD_TYPE = {
    NONE: {
        value: 'none',
        label: 'None'
    },
    WEEK: {
        value: 'week',
        label: 'Week'
    },
    MONTH: {
        value: 'month',
        label: 'Month'
    }
};

export const TRACK_TYPE = {
    HOUR: {
        value: 'hour',
        label: 'Hour'
    },
    DAY: {
        value: 'day',
        label: 'Day'
    }
}

const UNIX_DAY = 24 * 60 * 60;
const UNIX_WEEK = 7 * UNIX_DAY
const UNIX_MONTH = (days) => days * UNIX_DAY;

export const UNIX_TYPE = {
    UNIX_MAP: {
        month: UNIX_MONTH,
        week: UNIX_WEEK,
        day: UNIX_DAY
    },
    UNIX_DAY,
    UNIX_WEEK,
    UNIX_MONTH
};

export const STATIC_MAP_HOURS = {
	reportUsers: {
		label: "Report Users",
		suffix: "S"
	},
	totalHours: {
		label: "Total Hours",
		suffix: ""
	},
	averageHours: {
		label: "Average Hours",
		suffix: ""
	}
};

export const STATIC_MAP_DAYS = {
	reportUsers: {
		label: "Report Users",
		suffix: "S"
	},
	totalDays: {
		label: "Total Days",
		suffix: ""
	},
	averageDays: {
		label: "Average Days",
		suffix: ""
	}
};

export const PROJECT_TYPE = {
    active: {
        label: 'Active Projects',
        value: 'active'
    },
    inactive: {
        label: 'Inactive Projects',
        value: 'inactive'
    }
};