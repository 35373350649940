import { auth } from '../../firebase';
import { database } from '../../firebase';

import { userType } from '../constant/actionTypes';
import { getActiveUserSync, updateActiveUser } from './user';

const provider = new auth.GoogleAuthProvider();

provider.addScope('https://www.googleapis.com/auth/userinfo.email');
provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

export function googleSignInPopup() {
    auth().languageCode = 'en-US';

    return auth().signInWithPopup(provider);
}

export function googleSignOut() {
    return auth().signOut();
}


export function getAuthState() {
    return dispatch => {
        auth().onAuthStateChanged(async user => {
            if (user) {
                const { email } = user;
                const emailSuffix = email.split("@")[1];

                if (!["h8tch.com", "hatchbetter.com"].includes(emailSuffix)) {
                    await dispatch(verifyEmail(true, false));
                    googleSignOut();

                    return;
                }

                const currentUser = (await database.ref(`users/${user.uid}`).get()).val();

                if (currentUser?.inactive) {
                    await dispatch(inactiveUser(true, false));

                    return;
                }

                dispatch(getActiveUserSync(user));
            } else {
                dispatch(verifyEmail(false, true));
                dispatch(updateActiveUser({}, false));
            }
        });
    };
}

export function checkAuthState(authChecking) {
    return {
        type: userType.AUTH_CHECKING,
        authChecking
    };
}

export function verifyEmail(invalidEmail, authChecking) {
    return {
        type: userType.INVALID_EMAIL,
        invalidEmail,
        authChecking
    };
}


export function inactiveUser(isInactiveUser) {
    return {
        type: userType.INACTIVE_USER,
        isUserInactive: isInactiveUser,
        authChecking: false
    }
}