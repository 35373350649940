import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const config = process.env;

const firebaseConfig = {
	apiKey: "AIzaSyBTL-JBGooT6OlFbK1RGaIDGIelpedvxzQ",
	authDomain: "time-sheet-ffb91.firebaseapp.com",
	projectId: "time-sheet-ffb91",
	storageBucket: "time-sheet-ffb91.appspot.com",
	messagingSenderId: "892341010086",
	appId: "1:892341010086:web:45e7056f1dd1a95eb93e67",
	measurementId: "G-SMPR4F2E34",
	databaseURL: config.REACT_APP_DB_URL
};

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

const database = firebase.database();
const firebaseServer = firebase.database;
const auth = firebase.auth;

export { database, firebaseServer, auth };
