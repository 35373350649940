import React, { useEffect, useState } from 'react';
import {
    Form,
    Input,
    Modal,
    Button,
    Checkbox,
    DatePicker,
    InputNumber
} from 'antd';
import dayjs from 'dayjs';
import './index.css';

export default function LogModal({ visible, onCreate, onCancel, onEdit, data }) {
    const [form] = Form.useForm();
    const [overtimeCheck, setOvertimeCheck] = useState(false);
    const { editMode, okText, title, period, trackType, value = {} } = data;

    useEffect(() => {
        if (!Object.keys(value).length) {
            return;
        }

        if (value.option && value.option.find(x => x === 'overtime')) {
            setOvertimeCheck(true);
        }

        let fieldsValue = Object.assign({}, value);
        const { logDate, logDays } = value;

        if (logDate) {
            fieldsValue = Object.assign(fieldsValue, {
                logDate: dayjs.unix(logDate) || ''
            });
        }


        if (trackType === 'day' && !logDays) {
            fieldsValue = Object.assign(fieldsValue, {
                logDays: 1
            });
        }

        form.setFieldsValue(fieldsValue);
    }, [trackType, value, form]);

    const onOkClick = (isCreateAnother) => {
        const okCallback = editMode ? onEdit : onCreate;

        form.validateFields().then(fieldsValue => {
            setOvertimeCheck(false);

            if (!fieldsValue.option) {
                delete fieldsValue.option;
            }

            if (!fieldsValue.overtimeReason) {
                fieldsValue.overtimeReason = '';
            }

            let formData = Object.assign({}, fieldsValue);
            const logDate = fieldsValue['logDate'];

            if (logDate) {
                formData['logDate'] = dayjs(logDate.format('YYYY/MM/DD')).unix();
            } else {
                formData['logDate'] = '';
            }

            if (editMode) {
                formData = {
                    updateValue: { ...formData },
                    extraValue: value
                };
            }

            form.resetFields();
            okCallback(formData, isCreateAnother);
        }).catch(error => {
            console.log(error);
        });
    };

    const onCancelClick = () => {
        form.resetFields();
        setOvertimeCheck(false);
        onCancel();
    };

    const onOvertimeCheckBoxChange = (e) => {
        const { checked } = e.target;

        if (!checked) {
            form.setFieldsValue({
                overtimeReason: ''
            });
        }

        setOvertimeCheck(checked);
    };

    const setLogDateRules = () => {
        return period ? null : [{ required: true, message: 'You must specify a date on which the work occurred.' }];
    };

    const ModalFooter = () => {
        return (
            <>
                <Button key="Cancel" onClick={onCancelClick}>Cancel</Button>
                <Button key="Create" type="primary" onClick={() => onOkClick(false)}>{okText}</Button>
                { !editMode &&  <Button key="Create Another" type="primary" onClick={() => onOkClick(true)}>Create Another</Button> }
            </>
        );
    };

    const disabledDate = current => {
        if (!period) {
            return null;
        }
        
        const { startDate, endDate } = period;
        const date = dayjs(current.format('YYYY/MM/DD')).endOf('day');

        return date && date < dayjs.unix(startDate).endOf('day') || date > dayjs.unix(endDate).endOf('day');
    };

    return (
        <Modal
            title={title}
            visible={visible}
            onCancel={onCancelClick}
            footer={<ModalFooter/>}
        >
            <Form
                form={form}
                layout="vertical"
                name="create_log"
            >
                <Form.Item name="remark" label="Work Description" rules={[{ required: true, message: 'Please input task.' }]}>
                    <Input.TextArea rows={4} showCount maxLength={200} />
                </Form.Item>
                <Form.Item name="logDate" label="Date" rules={setLogDateRules()}>
                    <DatePicker format="YYYY/MM/DD" disabledDate={disabledDate}/>
                </Form.Item>
                {
                    trackType === 'day' ? (
                        <Form.Item name="logDays" label="Time Spent (Day)" rules={[{ required: true, message: 'You must indicate the time spent working.' }]}>
                            <InputNumber min={0} max={1} className="App-log__time-spent"/>
                        </Form.Item>
                    ) : (
                        <Form.Item name="logHours" label="Time Spent (Hour)" rules={[{ required: true, message: 'You must indicate the time spent working.' }]}>
                            <InputNumber min={0} max={100} className="App-log__time-spent"/>
                        </Form.Item>
                    )
                }
                <Form.Item name="option" label="Type (optional)">
                    <Checkbox.Group>
                        <Checkbox
                            onChange={onOvertimeCheckBoxChange}
                            value="overtime"
                        >
                            Overtime
                        </Checkbox>
                        <Checkbox
                            value="on-call stand by"
                        >
                            On-Call Stand By
                        </Checkbox>
                        <Checkbox
                            value="on-call support"
                        >
                            On-Call Support
                        </Checkbox>
                    </Checkbox.Group>
                </Form.Item>
                {
                    overtimeCheck &&
                    <Form.Item name="overtimeReason" label="Overtime Reason" rules={[{ required: true, message: 'Please input OT reason.' }]}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                }
            </Form>
        </Modal>
    );
}
